import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Banner from "../components/Banner";
import { Section, SectionText, TwoColumn } from "../components/Section";
import { Link } from "gatsby";
import Seo from "../components/Seo";
import { Helmet } from "react-helmet";

const HeroContainer = styled.section`
  background-color: #005e5d;
`;

const Hero = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
  column-gap: 4.5rem;
  row-gap: 2rem;
  max-width: 80rem;
  margin: auto;
  padding: 6rem 1rem 3rem 1rem;
  background-color: #005e5d;

  h1 {
    color: white;
    font-size: 4rem;
    font-weight: 800;
    line-height: 4rem;
    margin-top: 0.5rem;

    @media only screen and (max-width: 65rem) {
      font-size: 2.9rem;
      line-height: 3.5rem;
    }
  }

  h2 {
    color: #ddfcfd;
    font-size: 1.3rem;
    font-weight: 600;
  }

  h3 {
    color: #9aeaea;
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 0;
    max-width: 15rem;
  }

  @media only screen and (max-width: 65rem) {
    padding-top: 4rem;
  }
`;

const CallLink = styled.a`
  color: #7df3f1;
  text-decoration: none;

  &:hover {
    color: #72dbd9;
  }
`

const BookButton = styled(Link)`
  display: inline-block;
  margin: 2rem 0 1rem 0;
  padding: 1rem 0.5rem 1rem 1rem;
  border: none;
  border-radius: 10px;
  background-color: #b1eeef;
  color: #194043;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: 0.15s ease-out;

  &:hover {
    background-color: #abe6e7;
    -webkit-box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.05);
    transition: 0.1s ease-in;
  }

  &:active {
    outline: none;    
  }

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #book {
    transition: 0.2s;
  }

  &:hover #book {
    transform: translateX(4px);
    transition: 0.2s;
  }
`;

const Brands = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 80rem;
  margin: auto;
`;

const Brand = styled.div`
  width: 5.3rem;
  height: 50%;
  margin: 1rem;
  mix-blend-mode: multiply;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0%);
  }
`;

const TrustedBy = styled.div`
  padding: 0 1rem;
  h4 {
    margin: 1rem auto;
    max-width: 80rem;
    color: #3e3e3e;
    font-size: 1.2rem;
    text-align: center;
  }

  p {
    text-align: center;
    color: #666666;
    max-width: 40rem;
    margin: 1rem auto 2rem auto;
  }
`;

const Market = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MarketItem = styled.div`
  display: flex;
  grid-gap: 1rem;
  margin-top: 3rem;
  flex-basis: 20rem;
  flex-grow: 1;
  flex-shrink: 1;

  h4 {
    font-size: 1.3rem;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    color: #444;
  }
`;

const Services = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
`;

const Service = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.green ? "#e3eded" : "")};
  border-radius: 10px;
  padding: 1rem;

  h4 {
    margin: 0;
    font-weight: 700;
    font-size: 1.3rem;
    color: #002925;
  }

  p {
    font-size: 1.2rem;
    color: #002925;
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  transition: ${(props) => (props.linked ? "0.15s ease-out;" : "")};

  &:hover {
    ${(props) =>
    props.linked
      ? ` 
        background-color: #045D5D;
        h4, p, a {
          color: white;
        }
        box-shadow: 0px -1px 40px -15px rgba(110,101,101,1);
        -webkit-box-shadow: 0px -1px 40px -15px rgba(110,101,101,1);
        -moz-box-shadow: 0px -1px 40px -15px rgba(110,101,101,1);
        transform: scale(1.03);
        `
      : ""};
  }

`;

const Certifications = styled.div`
  display: flex;
  align-items: center;
`;

const Testimonials = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
`;

const Testimonial = styled.div`
  padding: 0.5rem 1.5rem;
  background-color: #045d5d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  p {
    color: #dcf4ef;
    font-size: 1.1rem;
  }

  .name {
    margin: 0;
    max-width: 15rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #ffffff;
  }

  h5 {
    margin-top: 0;
    max-width: 15rem;
    margin-bottom: 1.1rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: #e5f5f1;
  }
`;

export default function Home() {
  return (
    <Layout>
      <Seo
        title="A&D Demolition and Asbestos Removal"
        image="../images/hero-cover.jpg"
      />
      <Helmet>
        <script type="application/ld+json">
          {`
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "A&D Demolition",
  "image": "https://addemolition.com/static/c5f125af4b2307bbfeda9e1b0ee96a47/edcf8/hero-cover.webp",
  "@id": "",
  "url": "https://addemolition.com",
  "telephone": "(778) 340-8795",
  "areaServed":"Vancouver, North Vancouver, Burnaby, Abbotsford, Richmond, Squamish, West Vancouver, Port Moody, New Westminster, Surrey, Delta, Port Coquitlam, Coquitlam, Maple Ridge",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "163 Balmoral Rd W",
    "addressLocality": "North Vancouver",
    "addressRegion": "BC",
    "postalCode": "V7N 2T6",
    "addressCountry": "CA"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 49.3440399,
    "longitude": -123.073214
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  } 
}
`}
        </script>
      </Helmet>
      <HeroContainer>
        <Hero>
          <div>
            <h1>
              Demolition <br />
              and Asbestos Removal Services in
              <span style={{ color: "#7DF3F1" }}> Vancouver</span> and{" "}
              <span style={{ color: "#7DF3F1" }}>Lower Mainland</span>
            </h1>
            <h2>
              With over 10 years of experience, our team strives to offer you a
              quality job at a reasonable price, while making safety and
              punctuality our priority.
            </h2>

            <div style={{ display: "inline-flex", flexDirection: "column", alignItems: "start", background: "white", padding: "0.5rem 1rem 1rem 0.5rem", borderRadius: "1rem" }}>
              <StaticImage
                src="../images/worksafebc-logo.png"
                alt="WorkSafeBC Logo"
                height={50}
                placeholder="blurred"

              />
              <span style={{ paddingLeft: "0.5rem", fontWeight: "600", fontSize: "1.1rem" }}>
                Licensed to perform asbestos abatement work
              </span>
              <Link style={{paddingLeft:"0.5rem"}} to="https://www.worksafebc.com/en/health-safety/education-training-certification/asbestos-training-certification-licensing/find-licensed-contractors">Learn more</Link>
            </div>

            <h2>Call us: <CallLink href="tel:778-340-8795">(778) 340-8795</CallLink></h2>
            <BookButton to="/contact-us">
              <div className="button-content">
                Contact Us{" "}
                <img
                  src="../icons/rightArrow.svg"
                  alt="right arrow icon"
                  width="24"
                  height="24"
                  id="book"
                  loading="lazy"
                />
              </div>
            </BookButton>
            <h3>We offer FREE Asbestos Sampling and testing for renovations</h3>
          </div>
          <StaticImage
            src="../images/hero-cover.jpg"
            alt="A Bulldozer"
            objectFit="cover"
            height={500}
            placeholder="blurred"
            style={{
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
            }}
          />
        </Hero>
      </HeroContainer>
      <Section>
        <SectionText smallWidth>
          <h3 className="sub">ABOUT US</h3>
          <h3>Welcome to A&D Demolition</h3>
          <p>
            Whether you are a homeowner or a business proprietor, you can rely
            on our expertise to demolish residential and commercial buildings,
            or{"  "}
            <Link to="/markets">any other renovation project</Link>.
          </p>
        </SectionText>
        <Market>
          <MarketItem>
            <img
              src="../icons/checkmark.svg"
              alt="checkmark icon"
              width="25"
              height="25"
            />
            <div>
              <h4>Commercial</h4>
              <p>
                We have the experience and the right equipments to offer you a
                quality job at a reasonable price, making safety and punctuality
                our priority.
              </p>
            </div>
          </MarketItem>
          <MarketItem>
            <img
              src="../icons/checkmark.svg"
              alt="checkmark icon"
              width="25"
              height="25"
            />
            <div>
              <h4>Municipal</h4>
              <p>
                Safeguard your health with our Asbestos Removal Services. We
                deliver high quality jobs and take care of any chances of
                contamination from asbestos.
              </p>
            </div>
          </MarketItem>
          <MarketItem>
            <img
              src="../icons/checkmark.svg"
              alt="checkmark icon"
              width="25"
              height="25"
            />
            <div>
              <h4>Residential</h4>
              <p>
                Looking for hazardous waste disposal services for your home or
                business? We will help you with it.
              </p>
            </div>
          </MarketItem>
        </Market>
      </Section>
      <section>
        <TrustedBy>
          <h4>Trusted by</h4>
        </TrustedBy>
        <Brands>
          <Brand>
            <StaticImage
              src="../images/brands/ad-1.png"
              alt="Komcon Construction logo"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-2.png" alt="Earls logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-3.png"
              alt="DICATA Construction logo"
            />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-4.png"
              alt="MEDALLION PROJECTS logo"
            />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-5.png"
              alt="VITALITY Construction, INC. LOGO"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-6.png" alt="Anthem logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-7.png"
              alt="BATA DEVELOPMENTS logo"
            />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-8.png"
              alt="BOUYGUES CONSTRUCTION logo"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-9.png" alt="Richmond logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-10.png"
              alt="City of Kelowna logo"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-11.png" alt="KD logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-12.png"
              alt="Kerkhoff CONSTRUCTION logo"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-13.png" alt="KIA logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-14.png"
              alt="KINDRED CONSRUCTION logo"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-15.png" alt="G logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-16.png"
              alt="NORSON CONSTRUCTION LTD. logo"
            />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-17.png"
              alt="ON SIDE RESTORATION logo"
            />
          </Brand>
          <Brand>
            <StaticImage src="../images/brands/ad-18.png" alt="SCOTT logo" />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-19.png"
              alt="SEI PROJECT MANAGEMENT logo"
            />
          </Brand>
          <Brand>
            <StaticImage
              src="../images/brands/ad-20.png"
              alt="SEYLAN DEVELOPMENTS PLC"
            />
          </Brand>
        </Brands>
      </section>

      <Section>
        <Banner>
          <h3>Free asbestos sampling and testing for renovations</h3>
          <p>
            We offer FREE asbestos sampling and testing for renovations that get
            sent to our lab partner, so you can begin your project with peace of
            mind.
          </p>
          <Link to="contact-us">
            <div className="banner-btn-text">
              Contact us
              <img
                src="../icons/phone.svg"
                alt="phone icon"
                width="25"
                height="25"
                id="book"
              />
            </div>
          </Link>
        </Banner>
      </Section>
      <Section>
        <SectionText smallWidth>
          <h3 className="sub">SERVICES</h3>
          <h3>Asbestos and demolition</h3>

          <p>
            Our experienced team provides hazmat abatement solutions and
            hassle-free demolition, removing all waste in a timely manner.
          </p>
        </SectionText>
        <TwoColumn>
          <Services>
            <Service green linked>
              <Link to="services/asbestos-removal">
                <h4>Asbestos removal</h4>
                <p>
                  We ensure that all asbestos waste and any other contaminated
                  waste are arranged in sealed containers and labeled properly.
                </p>
              </Link>
            </Service>
            <Service green linked>
              <Link to="services/demolition">
                <h4>Demolition services</h4>
                <p>
                  Our experience enables us to provide hassle free construction
                  demolition without any environmental issues.{" "}
                </p>
              </Link>
            </Service>
            <Service green linked>
              <Link to="services/hazardous-waste-disposal">
                <h4>Hazardous waste disposal</h4>
                <p>
                  We take care of every step from demolition to transport and
                  disposal of all waste and hazardous materials, making your
                  safety our number one responsibility,{" "}
                </p>
              </Link>
            </Service>
          </Services>
          <StaticImage
            src="../images/about-2.jpg"
            height={600}
            alt="Four workers at a jobsite"
            placeholder="blurred"
            style={{
              boxShadow:
                "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              borderRadius: "5px",
            }}
          />
        </TwoColumn>
      </Section>

      <Section>
        <SectionText smallWidth>
          <p>
            <h3 className="sub">OUR VALUES</h3>
            <h3>Why choose us?</h3>
            Our experienced team provides hazmat abatement solutions and
            hassle-free demolition, removing all waste in a timely manner.
          </p>
        </SectionText>
        <TwoColumn>
          <StaticImage
            src="../images/hall.jpg"
            height={600}
            alt="A&D Demolition worker at a jobsite"
            placeholder="blurred"
            style={{
              boxShadow:
                "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
              borderRadius: "5px",
            }}
          />

          <Services>
            <Service>
              <h4>Professionalism</h4>
              <p>
                Our professional team has over 10 years of experience as
                demolition contractors in Vancouver. So, you can rely on our
                experience to handle any situation of demolition or asbestos
                removal, eliminating any risk of the process.
              </p>
            </Service>
            <Service>
              <h4>Safety</h4>
              <p>
                Your safety is A&D Demolition number one responsibility. We take
                care of every step from demolition, excavation, building
                deconstruction, and asbestos handling, to transport and disposal
                of all waste and hazardous materials.
              </p>
            </Service>
            <Service>
              <h4>We are certified by</h4>
              <Certifications>
                <StaticImage
                  src="../images/vrca.png"
                  alt="VRCA certification"
                  layout="fixed"
                  height={50}
                  placeholder="blurred"
                />
                <StaticImage
                  src="../images/CaGBC.png"
                  alt="CAGBC certification"
                  layout="fixed"
                  height={80}
                  placeholder="blurred"
                />
              </Certifications>
            </Service>
          </Services>
        </TwoColumn>
      </Section>

      <Section>
        <SectionText center smallWidth spaceBottom>
          <img
            src="../icons/quote.svg"
            alt="checkmark icon"
            width="80"
            height="80"
          />
          <h3 className="sub">TESTIMONIALS</h3>
          <h3>What our clients say about us</h3>
          <p>
            Building skyscrapers is like building new relationships: steady
            exterior construction requires solid and robust foundation.
          </p>
        </SectionText>
        <Testimonials>
          <Testimonial>
            <p>
              “We are happy to recommend A&D Demolition Corp. A&D Demolition has
              been hired on numerous occasions by our company for the abatement
              and removal of hazardous materials prior to demolition for our
              single and multifamily projects. Mr, Delfan and his team are well
              organized, provide excellent communication, and deliver quality
              results in an expedient manner. We highly recommend A&D Demolition
              team to anyone in need of HAZMAT abatement services, and will be
              continuing to use their services for our future projects.”{" "}
            </p>
            <div>
              <img
                src="../icons/thumbsUp.svg"
                alt="thumbs up icon"
                width="40"
                height="40"
              />
              <h4 className="name">Brian Saadatmandi</h4>
              <h5>President of Saadat Enterprises Ltd.</h5>
            </div>
          </Testimonial>
          <Testimonial>
            <p>
              “We acknowledge the high standard of performance and
              professionalism exhibited by A&D Demolition. The company was
              responsible for demolition work in our M. St. Joseph Hospital
              elevator renovation project. They demonstrated great attention and
              care working around hospital daily operations and completed their
              scope on time and with excellent quality. Both our site management
              and hospital staff were happy with their performance. We would
              certainly recommend them for any projects that include demolition
              or HAZMAT abatement.”
            </p>
            <div>
              <img
                src="../icons/thumbsUp.svg"
                alt="thumbs up icon"
                width="40"
                height="40"
              />
              <h4 className="name">Amin Khatoonabadi</h4>
              <h5>Project Manager of Scott Construction Ltd.</h5>
            </div>
          </Testimonial>
          <Testimonial>
            <p>
              “I wish to acknowledge the high standard of performance and
              professionalism exhibited by A&D Demolition. I have had the
              opportunity to work with A&D Demolition completing Removal and
              Disposal of Hazardous Materials and Demolition of the Gibson's
              Elementary School Project. A&D Demolition consistently
              demonstrated their commitment to the success of the project by
              their attention to detail on all phases that includes schedules,
              quality control, and administration. A&D completed their scope of
              work with no delays and on budget. We feel confident that A&D
              Demolition will do an outstanding job to meet your challenges as
              they have done for ours.”
            </p>
            <div>
              <img
                src="../icons/thumbsUp.svg"
                alt="thumbs up icon"
                width="40"
                height="40"
              />
              <h4 className="name">Alireza Eshaghzadeh</h4>
              <h5>Senior Project Manager of Bouygues Building Canada Inc.</h5>
            </div>
          </Testimonial>
          <Testimonial>
            <p>
              “Shahram and his team at A&D Demolition recently handled the
              asbestos and hazardous materials removal at our site in North
              Vancouver. We are very pleased with their services. Their quote
              was the most competitive and they completed their work with
              precision and speed. We recommend A&D Demolition and will work
              with them again in the future.”
            </p>
            <div>
              <img
                src="../icons/thumbsUp.svg"
                alt="thumbs up icon"
                width="40"
                height="40"
              />
              <h4 className="name">Kamran Tafreshi</h4>
              <h5>President of Kamcon Construction</h5>
            </div>
          </Testimonial>
          <Testimonial>
            <p>
              “I have found Mr. Delfan's work to be completed efficiently and
              competently. He maintains a clean and safe Jobsite, finishes on
              time and without any discussion of delay. It is my experience that
              Mr. Delfan can be relied upon to deliver exactly as promised. I am
              very happy to recommend his company.”
            </p>
            <div>
              <img
                src="../icons/thumbsUp.svg"
                alt="thumbs up icon"
                width="40"
                height="40"
              />
              <h4 className="name">Matthew Moradian</h4>
              <h5>President of Medallion Projects Ltd.</h5>
            </div>
          </Testimonial>
          <Testimonial>
            <p>
              "A&D Demolition Corp has removed all asbestos containing materials
              as per noted pre demolition survey and in accordance with all of
              the Worksafe BC requirements for the safe removal of asbestos
              containing materials.""
            </p>
            <div>
              <img
                src="../icons/thumbsUp.svg"
                alt="thumbs up icon"
                width="40"
                height="40"
              />
              <h4 className="name">Maintenance Development Supervisor</h4>
              <h5>City of Kelowna</h5>
            </div>
          </Testimonial>
        </Testimonials>
      </Section>

      <Section>
        <Banner darkGreen>
          <h3>View our realized asbestos & demolition projects</h3>
          <p>
            The works that we’re proud of and our clients are satisfied with.
          </p>
          <Link to="/projects">View projects</Link>
        </Banner>
      </Section>
    </Layout>
  );
}
